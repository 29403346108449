// Here you can add other styles

// Datatable row extend problem
.dApqnJ {
	display: block !important;
}

.expanded-component-wrapper {
	font-size: 13px;
}

.expanded-component-table {
	width: 100%;
	table-layout: fixed;

	tr {
		td:first-child {
			width:20%;
		}

		pre {
			white-space: pre-wrap;       /* css-3 */
			white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
			white-space: -pre-wrap;      /* Opera 4-6 */
			white-space: -o-pre-wrap;    /* Opera 7 */
			word-wrap: break-word;       /* Internet Explorer 5.5+ */
		}
	}
}

.job-widget {
	background: #9074f4;
}

.subscriptions {
	.plan-col {
		display: flex;

		.card {
			width: 100%;

			.card-body {
				position: relative;
				padding-bottom: 70px;

				.btn-subscribe-wrapper {
					position: absolute;
					bottom: 15px;
					width: 100%;
					left: 0;
				}
			}
		}
	}
}

.subscription-faq {
	.accordion-button::before {
	    flex-shrink: 0;
	    width: var(--cui-accordion-btn-icon-width);
	    height: var(--cui-accordion-btn-icon-width);
	    /* margin-left: auto; */
	    content: "";
	    background-image: var(--cui-accordion-btn-icon);
	    background-repeat: no-repeat;
	    background-size: var(--cui-accordion-btn-icon-width);
	    transition: var(--cui-accordion-btn-icon-transition);
	    margin-right: 15px;
	}

	.accordion-button:not(.collapsed)::before {
	    background-image: var(--cui-accordion-btn-active-icon);
	    transform: var(--cui-accordion-btn-icon-transform);
	}

	.accordion-button::after {
		display: none;
	}
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}
